import axios from "axios";
import info from "info";

const _GET = (url) => {
  return axios
    .get(info.api.API_ENDPOINT + url)
    .then(function (response) {
      return new Promise(function (resolve, reject) {
        resolve(response.data);
      });
    })
    .catch(function (error) {
      return new Promise(function (resolve, reject) {
        reject(error.response);
      });
    });
};

const apiRecordings = () => {
  return _GET("/recordings");
};

const apiMerch = () => {
  return _GET("/merch");
};

export { apiRecordings, apiMerch };
