import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { faSoundcloud } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { checkIfHttps } from "multiuse/check/check";

function StreamingPlatforms(props) {
  if (!props.url || !props.icon) {
    return null;
  }

  return (
    <>
      <a href={checkIfHttps(props.url)} target="_blank" rel="noreferrer">
        <FontAwesomeIcon
          size="lg"
          icon={
            props.icon.includes("spotify")
              ? faSpotify
              : props.icon.includes("soundcloud")
              ? faSoundcloud
              : props.icon.includes("youtube")
              ? faYoutube
              : faLock
          }
        />
      </a>
    </>
  );
}

export default StreamingPlatforms;
