import React from "react";
import MetaTags from "react-meta-tags";
import img from "images/recordings/wd.jpg";

function Tags(props) {
  return (
    <MetaTags>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta property="og:title" content={props.title} />
      <meta property="og:image" content={img} />
    </MetaTags>
  );
}

export default Tags;
