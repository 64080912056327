import React, { useEffect } from "react";
import routes from "routes";
import ReactGA from "react-ga";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "pages/home/Home";
import Community from "pages/community/Community";
import Error404 from "pages/errors/Error404";

function App() {
  return (
    <Router>
      <div>
        <GAPageView />
        <ScrollToTop />
        <Switch>
          <Route path={routes.home} exact component={Home}></Route>
          <Route path={routes.community} exact component={Community}></Route>
          <Route path="*" component={Error404} />
        </Switch>
      </div>
    </Router>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const GAPageView = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.pageview(pathname);
    }
  }, [pathname]);

  return null;
};

export default App;
