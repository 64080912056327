import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import MerchBlock from "./components/MerchBlock";
import { apiMerch } from "api";
// import ArrowDown from "multiuse/buttons/ArrowDown";
import "./style.scss";

function Merch() {
  const [loading, setLoading] = useState(true);
  const [merch, setMerch] = useState([]);

  useEffect(() => {
    setLoading(true);

    apiMerch().then((data) => {
      setMerch(data);
      setLoading(false);
    });
  }, []);

  // const id = "gigs";
  return (
    <Row
      id="merch"
      className="recordings justify-content-center align-items-center"
    >
      <Col xs="11" lg="10" xl="8" className="recordings-wrap">
        <Row className="card-wrapper grid show">
          <MerchBlock ready={!loading} merch={merch} />
        </Row>
      </Col>
      {/* <ArrowDown id={id} /> */}
    </Row>
  );
}

export default Merch;
