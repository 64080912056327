const checkIfHttps = (url) => {
  const https = "https://";
  const http = "http://";

  if (url.includes(https)) {
    return url;
  }
  if (url.includes(http)) {
    return url;
  } else {
    return http + url;
  }
};

const checkIfLineBreak = (text) => {
  return text.split("\r\n").map((str) => (
    <p>
      {str}
      <br></br>
    </p>
  ));
};

export { checkIfHttps, checkIfLineBreak };
