import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Card from "./../card/Card";
import MerchCard from "pages/home/sections/merch/components/MerchCard";
import Item from "../list_item/Item";
import "./style.scss";

function CardCarousel(props) {
  const handleDragStart = (e) => e.preventDefault();

  let items = [];

  if (props.merchendise) {
    const itemsPerSlide = {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    };
    let length = props.merch.length;

    props.merch.map(
      (rec, index) =>
        (items[index] = (
          <MerchCard
            key={index}
            length={length}
            img={rec.img}
            alt={rec.img_alt}
            vinyl={rec.vinyl}
            price={rec.price}
            date={rec.record_date}
            vinyl_img={rec.vinyl_img}
            title={rec.title}
            url={rec.purchase_url}
            onDragStart={handleDragStart}
          />
        ))
    );
    return (
      <AliceCarousel
        mouseTracking
        swipeDelta={70}
        items={items}
        responsive={itemsPerSlide}
        className="w-100 px-4"
      />
    );
  }
  let length = props.recordings.length;
  if (props.active) {
    const itemsPerSlide = {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    };

    props.recordings.map(
      (rec, index) =>
        (items[index] = (
          <Card
            key={index}
            img={rec.img}
            alt={rec.img_alt}
            length={length}
            title={rec.title}
            date={rec.release_date}
            spotify_url={rec.spotify_url}
            spotify_icon={rec.spotify_icon}
            soundcloud_url={rec.soundcloud_url}
            soundcloud_icon={rec.soundcloud_icon}
            youtube_url={rec.youtube_url}
            youtube_icon={rec.youtube_icon}
            lyrics_url={rec.lyrics_url}
            purchase={rec.purchase_url}
            onDragStart={handleDragStart}
          />
        ))
    );

    return (
      <AliceCarousel
        mouseTracking
        swipeDelta={70}
        items={items}
        responsive={itemsPerSlide}
        className="w-100 px-4"
      />
    );
  }
  const itemsPerSlide = {
    0: {
      items: 1,
    },
  };

  props.recordings.map(
    (rec, index) =>
      (items[index] = (
        <Item
          key={index}
          img={rec.img}
          alt={rec.img_alt}
          length={length}
          title={rec.title}
          date={rec.release_date}
          spotify_url={rec.spotify_url}
          spotify_icon={rec.spotify_icon}
          soundcloud_url={rec.soundcloud_url}
          soundcloud_icon={rec.soundcloud_icon}
          youtube_url={rec.youtube_url}
          youtube_icon={rec.youtube_icon}
          lyrics={rec.lyrics}
          lyrics_url={rec.lyrics_url}
          purchase={rec.purchase_url}
          onDragStart={handleDragStart}
        />
      ))
  );

  return (
    <AliceCarousel
      mouseTracking
      swipeDelta={70}
      items={items}
      responsive={itemsPerSlide}
      className="w-100 px-4"
    />
  );
}

export default CardCarousel;
