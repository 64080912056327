import { Row, Alert } from "react-bootstrap";
import "./style.scss";

function MainContent() {
  return (
    <Row
      id="/community"
      className="blog-buddies-wrap align-items-center justify-content-center"
    >
      <Row className="error-404 w-75">
        <Alert
          style={{
            backgroundColor: "rgba(0,0,0, .6)",
            color: "rgba(255,255,255, .8)",
            letterSpacing: "1px",
          }}
          className="w-100 text-center"
        >
          Community page is still a work in progress! Be patient and stay safe u
          legend!
        </Alert>
      </Row>
    </Row>
  );
}

export default MainContent;
