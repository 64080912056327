import React from "react";
import { Col, Card } from "react-bootstrap";
import StreamingPlatforms from "../multiuse/StreamingPlatforms";
import { checkIfHttps } from "multiuse/check/check";
import info from "info";
import "./style.scss";

function RecordingsCard(props) {
  return (
    <Col
      xs={props.length < 2 ? "4" : "12"}
      className="d-flex justify-content-center"
    >
      <Card style={{ maxWidth: "250px" }}>
        <Card.Img
          variant="top"
          src={info.backend.storage_url + props.img}
          alt={props.alt}
        />
        <div className="hover-text">
          <a
            href={checkIfHttps(props.lyrics_url)}
            target="_blank"
            rel="noreferrer"
          >
            <h3>lyrics</h3>
          </a>
          <hr></hr>
          <a
            href={checkIfHttps(props.purchase)}
            target="_blank"
            rel="noreferrer"
          >
            <h3>purchase</h3>
          </a>
        </div>
        <Card.Body>
          <Card.Title>
            {props.title} ({props.date})
          </Card.Title>
          <Card.Text>
            <StreamingPlatforms
              url={props.spotify_url}
              icon={props.spotify_icon}
            />
            <StreamingPlatforms
              url={props.soundcloud_url}
              icon={props.soundcloud_icon}
            />
            <StreamingPlatforms
              url={props.youtube_url}
              icon={props.youtube_icon}
            />
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default RecordingsCard;
