import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { Col } from "react-bootstrap";
// import FooterStreamingPlatforms from "pages/home/sections/recordings/components/multiuse/FooterSocialIcons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ClickAwayListener from "react-click-away-listener";
import MailChimp from "partials/newsletter/Newsletter";
import { fallDown as Menu } from "react-burger-menu";
import MobileNavLink from "./components/nav_link/MobileNavLink";
import MobileRouteLink from "./components/route_link/MobileRouteLink";
import MobileHashLink from "./components/hash_link/MobileHashLink";
import routes from "routes";
// import info from "info";
import "./style.scss";

function MobileMenu() {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  // const { language, updateLanguage } = useContext(LanguageContext);
  const location = useLocation();

  useEffect(() => {
    if (open === true) {
      document.body.classList.toggle("scroll-lock");
    } else {
      document.body.classList.remove("scroll-lock");
    }
  }, [open]);

  const handleClickAway = () => {
    setActive(false);
  };

  return (
    <Menu
      className="mobile-menu"
      isOpen={open}
      onStateChange={(state) => setOpen(state.isOpen)}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <ul>
          {location.pathname !== routes.home ? (
            <MobileRouteLink
              state={open}
              set={setOpen}
              to={routes.home}
              title="home"
            />
          ) : (
            <MobileNavLink
              state={open}
              set={setOpen}
              to={routes.home}
              title="home"
            />
          )}
          {location.pathname !== routes.home ? (
            <MobileHashLink
              state={open}
              set={setOpen}
              to="recordings"
              title="recordings"
            />
          ) : (
            <MobileNavLink
              state={open}
              set={setOpen}
              to="recordings"
              title="recordings"
            />
          )}
          {location.pathname !== routes.home ? (
            <MobileHashLink
              state={open}
              set={setOpen}
              to="merch"
              title="merch"
            />
          ) : (
            <MobileNavLink
              state={open}
              set={setOpen}
              to="merch"
              title="merch"
            />
          )}
          {location.pathname !== routes.home ? (
            <MobileHashLink
              state={open}
              set={setOpen}
              to="gigs"
              title="we giggin'"
            />
          ) : (
            <MobileNavLink
              state={open}
              set={setOpen}
              to="gigs"
              title="we giggin'"
            />
          )}

          <hr></hr>
          {location.pathname === routes.home ? (
            <MobileRouteLink
              state={open}
              set={setOpen}
              to={routes.community}
              title="community"
            />
          ) : (
            <MobileNavLink
              state={open}
              set={setOpen}
              to="community"
              title="community"
            />
          )}
          <MobileNavLink
            state={open}
            set={setOpen}
            to="contacts"
            title="contacts"
          />
          <li onClick={() => setActive(!active)} className="mobile-newsletter">
            newsletter
          </li>
        </ul>
        <div
          className={active ? "mail-chimp mobile active" : "mail-chimp mobile"}
        >
          <MailChimp />
        </div>
      </ClickAwayListener>
      {/* <Col
        className="text-center p-0"
        style={{ position: "absolute", bottom: "2rem", left: 0 }}
      >
        <FooterStreamingPlatforms stream={info.contacts.social} />
        <FooterStreamingPlatforms stream={info.contacts.stream} />
        <Col className="email mt-3">
          <a href="mailto:lakenotion@gmail.com">
            <FontAwesomeIcon size="lg" icon={faEnvelope} />
          </a>
        </Col>
      </Col> */}
    </Menu>
  );
}

export default MobileMenu;
