import React from "react";
import { Row, Spinner } from "react-bootstrap";

function Loader() {
  return (
    <Row
      className="justify-content-center align-items-center"
      style={{ minHeight: "600px" }}
    >
      <Spinner animation="border" size="md" variant="light" />
    </Row>
  );
}

export default Loader;
