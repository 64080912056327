import { ScrollingProvider, Section } from "react-scroll-section";
import Main from "./sections/main_section/Main";
import Recordings from "./sections/recordings/Recordings";
import Merch from "./sections/merch/Merch";
import Gigs from "./sections/we_giggin/Gigs";
import Footer from "partials/footer/Footer";
import Tags from "multiuse/meta_tags/Tags";
import info from "info";

import "./style.scss";
import Header from "partials/header/Header";

function Home() {
  return (
    <div id="/" className="home fade-in custom-cursor">
      <Tags
        title={info.tags.home_title}
        description={info.tags.home_description}
      />
      <ScrollingProvider>
        <Header />
        <Section id="home" to="home">
          <Main />
        </Section>
        <Section id="recordings">
          <Recordings />
        </Section>
        <Section id="merch">
          <Merch />
        </Section>
        <Section id="we giggin'">
          <Gigs />
        </Section>
        <Section id="contacts">
          <Footer />
        </Section>
      </ScrollingProvider>
    </div>
  );
}

export default Home;
