import React from "react";
import { Link as Smooth } from "react-scroll";
import "./style.scss";

function MobileNavLink(props) {
  return (
    <li>
      <Smooth
        onClick={() => props.set(!props.state)}
        to={props.to}
        smooth={true}
        offset={-70}
        duration={500}
      >
        {props.title}
      </Smooth>
    </li>
  );
}

export default MobileNavLink;
