import React from "react";
import { Col, Row } from "react-bootstrap";
import StreamingPlatforms from "../multiuse/StreamingPlatforms";
import { ReactComponent as Genius } from "images/icons/genius_logo.svg";
import { checkIfHttps, checkIfLineBreak } from "multiuse/check/check";
import info from "info";
import "./style.scss";

function Item(props) {
  return (
    <Row className="list-content">
      <Col md="5" lg="4" className="text-center text-md-left">
        <img
          src={info.backend.storage_url + props.img}
          alt={props.alt}
          className="img-fluid"
        ></img>
      </Col>
      <Col
        md="7"
        lg="8"
        className="text-left"
        style={{ maxHeight: "350px", overflow: "auto" }}
      >
        <h3 className="text-center text-md-left mb-4 mt-4 mb-md-2 mt-md-0">
          {props.title}
        </h3>
        <p className="text-left">released in {props.date}</p>
        <Row className="stream d-block d-sm-flex mb-3">
          <p>stream here:</p>{" "}
          <StreamingPlatforms
            url={props.spotify_url}
            icon={props.spotify_icon}
          />
          <StreamingPlatforms
            url={props.soundcloud_url}
            icon={props.soundcloud_icon}
          />
          <StreamingPlatforms
            url={props.youtube_url}
            icon={props.youtube_icon}
          />
        </Row>
        <p>
          lyrics:{" "}
          <a
            href={checkIfHttps(props.lyrics_url)}
            target="_blank"
            rel="noreferrer"
          >
            <Genius style={{ width: "100px" }} />
          </a>
        </p>
        <div className="lyrics">
          {props.lyrics ? checkIfLineBreak(props.lyrics) : null}
        </div>
      </Col>
    </Row>
  );
}

export default Item;
