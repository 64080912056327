import React, { useState, useEffect } from "react";
// import ArrowDown from "multiuse/buttons/ArrowDown";
import { Row } from "react-bootstrap";
import RecordingsBlock from "./components/blocks/RecordingsBlock";
import { apiRecordings } from "api";
import "./style.scss";

function Recordings() {
  const [loading, setLoading] = useState(true);

  const [recordings, setRecordings] = useState([]);

  useEffect(() => {
    setLoading(true);

    apiRecordings().then((data) => {
      setRecordings(data);
      setLoading(false);
    });
  }, []);
  // const id = "merch";
  return (
    <Row
      id="recordingz"
      className="recordings justify-content-center align-items-center"
    >
      <RecordingsBlock recordings={recordings} ready={!loading} />
      {/* <ArrowDown id={id} /> */}
    </Row>
  );
}

export default Recordings;
