import React from "react";
import { Row } from "react-bootstrap";

function Message() {
  return (
    <Row
      className="justify-content-center align-items-center"
      style={{ minHeight: "600px" }}
    >
      <p
        className="mb-0 mx-4 text-center"
        style={{ color: "rgba(255, 255, 255, 0.6)", letterSpacing: ".9px" }}
      >
        Hold up! We haven't added anything yet, but be patient my friend.
      </p>
    </Row>
  );
}
export default Message;
