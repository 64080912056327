import React from "react";
import { Link } from "react-router-dom";

function MobileRouteLink(props) {
  return (
    <li>
      <Link
        onClick={() => props.set(!props.state)}
        to={props.to}
        smooth="true"
        offset={-70}
        duration={500}
      >
        {props.title}
      </Link>
    </li>
  );
}

export default MobileRouteLink;
