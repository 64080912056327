import React from "react";
import { Row, Col } from "react-bootstrap";
import ArrowDown from "multiuse/buttons/ArrowDown";
// import StreamingPlatforms from "pages/home/sections/recordings/components/multiuse/StreamingPlatforms";
// import promo from "images/recordings/poloriod.jpeg";
import "./style.scss";

function Main() {
  //   const [selected, setSelected] = useState(false);

  //   const handleSelected = (bool) => {
  //     setSelected(bool);
  //   };

  const id = "recordingz";
  return (
    <Row
      id="main"
      className="main recordings align-items-center justify-content-center"
    >
      <Col xs="11" lg="10" xl="8" className="text-center iframe-container p-0">
        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/yKdRS6m_yo4"
            title="Lake boisss"
            frameborder="0"
          ></iframe>
        </div>
      </Col>
      {/* <Col>
        <Card
          style={{ maxWidth: "350px" }}
          className="newsletter-campaign mx-auto"
          onClick={() => handleSelected(true)}
          onMouseEnter={() => handleSelected(true)}
          onMouseLeave={() => handleSelected(false)}
        >
          <Card.Img
            variant="top"
            src={promo}
            style={{ opacity: "0.8" }}
            className={selected ? "mx-auto active " : "mx-auto"}
          />
          <div
            className={
              selected
                ? "campaign-hover-text newsletter-campaign-text px-2 px-md-4 d-flex align-items-center active"
                : "campaign-hover-text newsletter-campaign-text px-2 px-md-4"
            }
          >
            * we started calling our recording sessions at Val's place "Sunday
            Services" after we realized that almost every recording session up
            until then happened on a Sunday
          </div>
          <Card.Body>
            <Card.Title
              style={{ marginBottom: "0.5rem" }}
              className="newsletter-campaign-text"
            >
              Sunday's service* with our pal Val
            </Card.Title>
            <Card.Text>
              <StreamingPlatforms
                url="https://open.spotify.com/track/1zXr9SZ7j3lKQ4g9D7XsNt?si=GzINN2rbQkufw4tTMVtzPA"
                icon="spotify"
              />
              <StreamingPlatforms
                url="https://soundcloud.com/lakenotion/lake-notion-every-so-often"
                icon="soundcloud"
              />
              <StreamingPlatforms
                url="https://www.youtube.com/watch?v=4RMNIVUDv0U&ab_channel=lakenotion"
                icon="youtube"
              />
            </Card.Text>
          </Card.Body>
        </Card>
      </Col> */}
      <ArrowDown id={id} />
    </Row>
  );
}

export default Main;
