import React from "react";
import { Col, Row } from "react-bootstrap";
import FooterStreamingPlatforms from "pages/home/sections/recordings/components/multiuse/FooterSocialIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import CopyRight from "./components/CopyRight";
import info from "info";
import "./style.scss";

function Footer() {
  return (
    <footer id="contacts" className="footer">
      <Row className="footer-wrap">
        <Col className="text-center my-auto p-0">
          <FooterStreamingPlatforms stream={info.contacts.social} />
          <FooterStreamingPlatforms stream={info.contacts.stream} />
          <Col className="email mt-3">
            <a href="mailto:lakenotion@gmail.com">
              <FontAwesomeIcon size="lg" icon={faEnvelope} />
            </a>
          </Col>
        </Col>
        <CopyRight />
      </Row>
    </footer>
  );
}

export default Footer;
