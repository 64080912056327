import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { faSoundcloud } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

const Info = {
  api: { API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT + "/api" },
  backend: { storage_url: process.env.REACT_APP_API_ENDPOINT + "/storage/" },
  contacts: {
    email: "lakenotion@gmail.com",
    stream: [
      {
        url:
          "open.spotify.com/artist/2Wd7oUYCIXw107xxAloRfu?si=e-drxogPQ_iSL2aKpbQkeQ",
        icon: faSpotify,
      },
      { url: "soundcloud.com/lakenotion", icon: faSoundcloud },
      { url: "youtube.com/channel/UCdgOGfN9vWLLIN7Js_8dllQ", icon: faYoutube },
    ],
    social: [
      { url: "facebook.com/lakenotion", icon: faFacebook },
      { url: "instagram.com/lakenotion/", icon: faInstagram },
      { url: "twitter.com/lakenotion", icon: faTwitter },
    ],
  },
  newsletter: {
    url:
      "https://gmail.us7.list-manage.com/subscribe/post?u=646186a9da05f29dd2ccf7b08&amp;id=1d1f866de2",
  },
  tags: {
    home_title: "Lake Notion",
    home_description: "",
    community_title: "Lake Notion | Community",
    community_description: "",
  },
  trackingId: {
    id: "UA-187923261-1",
  },
};

export default Info;
