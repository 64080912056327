import { Alert, Container, Row } from "react-bootstrap";
import BgrImg from "images/backgrounds/community_bgr_compress.jpg";
import Header from "partials/header/Header";
import Footer from "partials/footer/Footer";
import "./style.scss";

let BgrStyle = {
  background:
    "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1)), url(" +
    BgrImg +
    ") center center no-repeat",
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
};

function Error404() {
  return (
    <div className="community" style={BgrStyle}>
      <Header />
      <Container style={{ minHeight: "40vh", display: "flex" }}>
        <Row className="error-404 align-items-end justify-content-center w-100">
          <Alert
            style={{
              backgroundColor: "rgba(0,0,0, .6)",
              color: "rgba(255,255,255, .8)",
            }}
            className="w-100 text-center"
          >
            Kļūda 404 - Lapa netika atrasta
          </Alert>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Error404;
