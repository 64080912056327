import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import routes from "routes";
import { useScrollSection } from "react-scroll-section";
import ClickAwayListener from "react-click-away-listener";
import { Col, Row } from "react-bootstrap";
import NavLink from "./components/nav_link/NavLink";
import MobileMenu from "partials/header/components/mobile_menu/MobileMenu";
import ReactTooltip from "react-tooltip";
import MailChimp from "partials/newsletter/Newsletter";
import { ReactComponent as Home } from "images/icons/home.svg";
import { ReactComponent as Recordings } from "images/icons/recordings.svg";
import { ReactComponent as Merch } from "images/icons/merch.svg";
import { ReactComponent as Gigs } from "images/icons/gigs.svg";
import { ReactComponent as Community } from "images/icons/community.svg";
import { ReactComponent as Contacts } from "images/icons/contacts.svg";
import { ReactComponent as Newsletter } from "images/icons/newsletter.svg";
import { ReactComponent as Logo } from "images/logo.svg";
import Hash from "./components/hash_link/Hash";
import { Link as Smooth } from "react-scroll";
import "./style.scss";

function Header() {
  const [active, setActive] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [mobile, setMobile] = useState(false);
  const location = useLocation();

  const homeSection = useScrollSection("home");
  const recSection = useScrollSection("recordings");
  const merchSection = useScrollSection("merch");
  const gigsSection = useScrollSection("we giggin'");
  const contactsSection = useScrollSection("contacts");
  const comSection = useScrollSection("community");

  const handleClickAway = () => {
    setActive(false);
  };

  const addActive = () => {
    if (window.innerWidth < 767) {
      if (window.scrollY >= 100) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    } else if (window.scrollY >= 500) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  const addMobile = () => {
    if (window.innerWidth < 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", addActive);
    return () => window.removeEventListener("scroll", addActive);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", addMobile);
    return () => window.removeEventListener("resize", addMobile);
  }, []);

  return (
    <div
      className={
        navbar || mobile || window.innerWidth < 768
          ? "header active py-3"
          : "header py-3"
      }
    >
      <Row className="w-100">
        <Col
          md="5"
          className="d-none d-md-flex align-items-center flex-row-reverse"
        >
          {location.pathname === routes.home ? (
            <>
              <NavLink
                svg={<Gigs />}
                mouse={gigsSection.onClick}
                selected={gigsSection.selected}
                to="we giggin'"
                title="we giggin'"
              />
              <NavLink
                svg={<Merch />}
                mouse={merchSection.onClick}
                selected={merchSection.selected}
                to="merch"
                title="merch"
              />
              <NavLink
                svg={<Recordings />}
                mouse={recSection.onClick}
                selected={recSection.selected}
                to="recordingz"
                title="recordings"
              />
              <NavLink
                svg={<Home />}
                mouse={homeSection.onClick}
                selected={homeSection.selected}
                to="home"
                title="home"
              />
            </>
          ) : (
            <>
              <Hash
                svg={<Gigs />}
                mouse={gigsSection.onClick}
                selected={gigsSection.selected}
                to="/"
                hash="we giggin'"
                title="we giggin'"
              />
              <Hash
                svg={<Merch />}
                mouse={merchSection.onClick}
                selected={merchSection.selected}
                to="/"
                hash="merch"
                title="merch"
              />
              <Hash
                svg={<Recordings />}
                mouse={recSection.onClick}
                selected={recSection.selected}
                to="/"
                hash="recordingz"
                title="recordings"
              />
              <Hash
                svg={<Home />}
                mouse={homeSection.onClick}
                selected={homeSection.selected}
                to="/"
                hash=""
                title="home"
              />
            </>
          )}
        </Col>
        <Col xs="7" md="2" className="pr-3 text-left text-md-center">
          <Logo className="logo" />
        </Col>
        <Col md="5" className="text-left d-none d-md-flex align-items-center">
          {location.pathname === routes.community ? (
            <NavLink
              svg={<Community />}
              mouse={comSection.onClick}
              selected={comSection.selected}
              to={routes.community}
              title="community"
            />
          ) : (
            <Hash
              svg={<Community />}
              mouse={comSection.onClick}
              selected={comSection.selected}
              to={routes.community}
              hash={routes.community}
              title="community"
            />
          )}
          <NavLink
            svg={<Contacts />}
            onClick={contactsSection.onClick}
            selected={contactsSection.selected}
            to="contacts"
            title="contacts"
          />
          {/* <-- small hack, do no delete --> */}
          <NavLink svg={null} to="" title="" />
          {/* <-- small hack, do no delete --> */}

          <ClickAwayListener
            onClickAway={handleClickAway}
            className="mt-auto mb-auto"
          >
            <Smooth
              smooth="true"
              offset={-70}
              onClick={() => setActive(!active)}
              data-tip="newsletter"
              className="newsletter"
              duration={500}
              to={
                location.pathname === routes.community
                  ? routes.community
                  : routes.home
              }
            >
              <Newsletter
                onClick={() => setActive(!active)}
                className="newsletter"
              />
              <ReactTooltip
                className="tooltip-newsletter"
                place="right"
                effect="solid"
              />
            </Smooth>
            <div
              className={!active || navbar ? "mail-chimp" : "mail-chimp active"}
            >
              <p
                className="m-0"
                style={{
                  position: "absolute",
                  right: "0.5rem",
                  top: "0.5rem",
                  fontSize: "11px",
                  color: "rgba(255, 208, 70, 0.85)",
                  borderRadius: "3px",
                }}
              >
                newsletter
              </p>
              <MailChimp />
              {/* <div
                className="tooltip-newsletter-big mx-auto"
                ref={setTooltip}
                style={styles.popper}
                {...attributes.popper}
              >
                newsletter
              </div> */}
            </div>
          </ClickAwayListener>
        </Col>
        <Col xs="5" className="d-flex d-md-none">
          <MobileMenu />
          {/* <div className={navbar ? "mail-chimp" : "mail-chimp active"}>
            <MailChimp />
            <div
              className="tooltip-newsletter-big mx-auto"
              ref={setTooltip}
              style={styles.popper}
              {...attributes.popper}
            >
              newsletter
            </div>
          </div> */}
          {location.pathname === routes.home ? (
            <div
              className={!active || navbar ? "mail-chimp" : "mail-chimp active"}
            >
              <p
                className="m-0"
                style={{
                  position: "absolute",
                  right: "0.5rem",
                  top: "0.5rem",
                  fontSize: "11px",
                  color: "rgba(255, 208, 70, 0.85)",
                  borderRadius: "3px",
                }}
              >
                newsletter
              </p>
              <MailChimp />
              {/* <div
                className="tooltip-newsletter-big mx-auto"
                ref={setTooltip}
                style={styles.popper}
                {...attributes.popper}
              >
                newsletter
              </div> */}
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
}

export default Header;
