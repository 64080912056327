import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
// import ArrowDown from "multiuse/buttons/ArrowDown";
import "./style.scss";

function Gigs() {
  // const id = "contacts";
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widget.bandsintown.com/main.min.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Row
      id="gigs"
      className="recordings justify-content-center align-items-center pb-5"
    >
      <Col xs="11" lg="10" className="recordings-wrap">
        <Row className="card-wrapper grid show px-0 px-md-3">
          <Col className="px-0 px-md-3">
            <div
              className="bit-widget-initializer"
              data-artist-name="Lake Notion"
              data-display-local-dates="false"
              data-display-past-dates="false"
              data-auto-style="false"
              data-text-color="rgba(255,255,255,0,9)"
              data-link-color="rgba(0,0,0,0.7)"
              data-background-color="rgba(0,0,0,0)"
              data-display-limit="5"
              data-font="LemonMilk"
              data-link-text-color="rgba(255,255,255,0,9)"
              data-display-lineup="true"
              data-display-play-my-city="false"
              data-separator-color="rgba(255, 255, 255, 0.5)"
              data-display-start-time="true"
            ></div>
          </Col>
        </Row>
      </Col>
      {/* <ArrowDown id={id} /> */}
    </Row>
  );
}

export default Gigs;
