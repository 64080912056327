import React from "react";
import { HashLink } from "react-router-hash-link";
import routes from "routes";

function MobileRouteLink(props) {
  return (
    <li>
      <HashLink
        onClick={() => props.set(!props.state)}
        to={routes.home + "#" + props.to}
        smooth={true}
        offset={-70}
        duration={500}
      >
        {props.title}
      </HashLink>
    </li>
  );
}

export default MobileRouteLink;
