import React, { useState, useEffect } from "react";
import { Link as Smooth } from "react-scroll";
import { ReactComponent as Arrow } from "images/icons/arrows-right.svg";
import "./style.scss";

function ArrowDown(data) {
  const [hide, setHide] = useState(false);

  const addHide = () => {
    if (window.scrollY >= 300) {
      setHide(true);
    } else {
      setHide(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", addHide);
    return () => window.removeEventListener("scroll", addHide);
  }, []);

  return (
    <Smooth
      to={data.id}
      smooth="true"
      offset={-30}
      duration={500}
      className={hide ? "button-down hidden" : "button-down"}
      // className="button-down"
    >
      <Arrow />
    </Smooth>
  );
}

export default ArrowDown;
