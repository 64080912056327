import React, { useState } from "react";
import { Link as Smooth } from "react-scroll";
import { Button } from "react-bootstrap";
import { usePopper } from "react-popper";
import ReactTooltip from "react-tooltip";
import "./style.scss";

function NavLink(data) {
  const [clickElement, setClickElement] = useState(null);
  const [tooltip, setTooltip] = useState(null);
  const { styles, attributes } = usePopper(clickElement, tooltip, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  return (
    <>
      <Smooth
        to={data.to}
        data-tip={data.title}
        data-event="mouseenter mouseleave"
        data-iscapture="true"
        data-for="icon"
        smooth="true"
        offset={data.to === "contacts" ? -25 : -50}
        duration={500}
        className={data.selected ? "header-svg active" : "header-svg"}
      >
        <Button type="button" onClick={data.mouse} ref={setClickElement}>
          {data.svg}
        </Button>
        <ReactTooltip
          isSticky={true}
          id="icon"
          place="bottom"
          effect="solid"
          clickable={true}
          className="tooltip"
          arrowColor="inherit"
        />
      </Smooth>
      <div
        ref={setTooltip}
        style={styles.popper}
        {...attributes.popper}
        className={data.selected ? "fade-in" : "popper-hide"}
      >
        {data.title}
      </div>
    </>
  );
}

export default NavLink;
