import React from "react";
import Mailchimp from "react-mailchimp-form";
import info from "info";
import "./style.scss";

const MAILCHIMP_URL = info.newsletter.url;

const formMessages = {
  sending: "Sending...",
  success: "You strong!",
  error:
    "Something has gone mildly fucked. Try it again after few seconds. If it still doesn't work, let us know!",
  empty: "bruuuuh, you must put in an e-mail, bud",
  duplicate: "Boy, you relentless - we already have your email, buddy",
  button: "submit if you strong",
};

const formFields = [
  {
    name: "EMAIL",
    placeholder: "E-mail",
    type: "email",
    required: true,
  },
];

function Newsletter() {
  return (
    <Mailchimp
      action={MAILCHIMP_URL}
      messages={formMessages}
      fields={formFields}
    />
  );
}

export default Newsletter;
