import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import CardSlider from "./../slider/CardSlider";
import Loader from "multiuse/Loader";
import Message from "multiuse/Message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { faTh } from "@fortawesome/free-solid-svg-icons";
import "./../../style.scss";

function RecordingsBlock(props) {
  const [active, setActive] = useState(true);

  if (!props.ready) {
    return (
      <Col xs="11" lg="10" xl="8" className="recordings-wrap">
        <Loader />
      </Col>
    );
  }
  if (props.recordings.length === 0) {
    return (
      <Col xs="11" lg="10" xl="8" className="recordings-wrap">
        <Message />
      </Col>
    );
  }

  return (
    <Col xs="11" lg="10" xl="8" className="recordings-wrap">
      <Row className="text-right">
        <Col className="list-grid">
          <FontAwesomeIcon
            onClick={() => setActive(true)}
            size="1x"
            icon={faTh}
            className={active ? "active" : ""}
          />
          <FontAwesomeIcon
            onClick={() => setActive(false)}
            size="1x"
            icon={faList}
            className={active ? "" : "active"}
          />
        </Col>
      </Row>
      <Row
        className={
          active ? "card-wrapper grid d-block show" : "card-wrapper grid"
        }
      >
        <CardSlider recordings={props.recordings} active={active} />
      </Row>
      <Row className={active ? "card-wrapper list" : "card-wrapper list show"}>
        <CardSlider recordings={props.recordings} active={active} />
      </Row>
    </Col>
  );
}

export default RecordingsBlock;
