import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Button } from "react-bootstrap";
import { usePopper } from "react-popper";
import ReactTooltip from "react-tooltip";

function Hash(data) {
  const [clickElement, setClickElement] = useState(null);
  const [tooltip, setTooltip] = useState(null);
  const { styles, attributes } = usePopper(clickElement, tooltip);

  return (
    <>
      <HashLink
        to={data.to + "#" + data.hash}
        data-tip={data.title}
        data-event="mouseenter mouseleave"
        data-iscapture="true"
        data-for="icon"
        smooth="true"
        offset={-70}
        duration={500}
      >
        <Button type="button" onClick={data.mouse} ref={setClickElement}>
          {data.svg}
        </Button>
        <ReactTooltip
          isSticky={true}
          id="icon"
          place="bottom"
          effect="solid"
          clickable={false}
          className="tooltip"
          arrowColor="inherit"
        />
      </HashLink>
      <div
        ref={setTooltip}
        style={styles.popper}
        {...attributes.popper}
        className={data.selected ? "fade-in" : "popper-hide"}
      >
        {data.title}
      </div>
    </>
  );
}

export default Hash;
