import React from "react";
import { Row } from "react-bootstrap";
import CardSlider from "pages/home/sections/recordings/components/slider/CardSlider";
import Loader from "multiuse/Loader";
import Message from "multiuse/Message";
import "./../style.scss";

function MerchBlock(props) {
  if (!props.ready) {
    return (
      <Row className="w-100 justify-content-center">
        <Loader />
      </Row>
    );
  }
  if (props.merch.length === 0) {
    return (
      <Row className="w-100 justify-content-center">
        <Message />
      </Row>
    );
  }

  return <CardSlider merchendise={true} merch={props.merch} />;
}

export default MerchBlock;
