import React from "react";
import { Row, Col } from "react-bootstrap";

function CopyRight() {
  let date = new Date().getFullYear();

  return (
    <Row className="copy-right text-center mb-4">
      <Col xs="12">
        site design / logo{" "}
        <strong style={{ fontFamily: "sans-serif" }}>&copy;</strong> {date} ya
        bois Lake Notion
      </Col>
      <Col xs="12">
        developed by{" "}
        <a href="https://yattasoftware.com/" target="_blank" rel="noreferrer">
          {/* Yatta Software */}
          Ya boi Norris
        </a>
      </Col>
    </Row>
  );
}

export default CopyRight;
