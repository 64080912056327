import React from "react";
import { Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { checkIfHttps } from "multiuse/check/check";
import info from "info";
import "./style.scss";

function MerchCard(props) {
  return (
    <Col
      xs={props.length <= 2 ? "12" : "4"}
      className={props.length > 1 ? "d-flex justify-content-center" : ""}
    >
      <Card style={{ maxWidth: "250px" }}>
        <a
          href={checkIfHttps(props.url)}
          target="_blank"
          rel="noreferrer"
          className={props.vinyl ? "top-img" : "merch-img"}
        >
          <Card.Img
            variant="top"
            src={info.backend.storage_url + props.img}
            alt={props.alt}
          />
        </a>
        {props.vinyl ? (
          <a
            href={checkIfHttps(props.url)}
            target="_blank"
            rel="noreferrer"
            className="vinyl-img"
          >
            <img
              src={info.backend.storage_url + props.vinyl_img}
              alt={props.alt}
            ></img>
          </a>
        ) : null}
        <Card.Body style={{ letterSpacing: "0.9px" }}>
          <Card.Title style={{ marginBottom: "0.5rem" }}>
            {props.title}
          </Card.Title>
          <a
            href={checkIfHttps(props.url)}
            className="vinyl-link"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon size="lg" icon={faShoppingCart} />
          </a>
          {props.price}€
        </Card.Body>
      </Card>
    </Col>
  );
}

export default MerchCard;
