import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FooterStreamingPlatforms(props) {
  if (!props.stream) {
    return null;
  }

  return (
    <>
      {props.stream.map((platform, index) => (
        <a
          key={index}
          href={"https://" + platform.url}
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon size="lg" icon={platform.icon} />
        </a>
      ))}
    </>
  );
}

export default FooterStreamingPlatforms;
