// import { ScrollingProvider, Section } from "react-scroll-section";
import Header from "partials/header/Header";
// import Blog from "./components/blog/Blog";
// import Buddies from "./components/buddies/Buddies";
import Footer from "partials/footer/Footer";
import info from "info";
import Tags from "multiuse/meta_tags/Tags";
import "./style.scss";
import MainContent from "./components/main_content/MainContent";

function Community() {
  return (
    <div className="community fade-in">
      <Tags
        title={info.tags.community_title}
        description={info.tags.community_description}
      />
      <Header />
      <MainContent />
      <Footer />
    </div>
  );
}

export default Community;
